/* eslint-disable no-restricted-globals */
import axios from 'axios';
import { MessageBox, Message } from 'element-ui';
import store from '@/store';
import { getToken } from '@/utils/auth';

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent

    if (store.getters.token) {
      // let each request carry token
      // please modify it according to the actual situation
      config.headers.token = getToken();
    }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  // eslint-disable-next-line comma-dangle
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;
    if (res.code !== '200') {
      if (res.code === '510') {
        MessageBox.confirm('登录凭证已过期或已在其他地方登录，请重新登录！', '确认注销', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: '警告',
        })
          .then(() => {
            store
              .dispatch('user/resetToken')
              .then(() => {
                location.reload();
              })
              .catch(() => {
                store.dispatch('user/resetToken').then(() => {
                  location.reload();
                });
              });
          })
          .catch(() => {
            store.dispatch('user/resetToken').then(() => {
              location.reload();
            });
          });
      } else {
        Message({
          message: res.message.message || 'Error',
          type: 'error',
          showClose: true,
          duration: 5 * 1000,
        });
      }
      return Promise.reject(new Error(res.message || 'Error'));
    } if (res.message.success) {
      return res.message;
    }
    if (!res.message.message) {
      return true;
    }
    Message({
      message: res.message.message || 'Error',
      type: 'error',
      showClose: true,
      duration: 5 * 1000,
    });
    return Promise.reject(new Error(res.message.message || 'Error'));
  },
  (error) => {
    console.log(`err${error}`); // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  // eslint-disable-next-line comma-dangle
  }
);

export default service;
